import React from "react"

import { CgWebsite } from "react-icons/cg"
import { FaYoutube } from "react-icons/fa"

export const LinksData = [
  {
    icon: <CgWebsite />,
    title: "Pilots Workshop Website",
    url: "http://www.pilots-workshop.com",
  },
  {
    icon: <FaYoutube />,
    title: "YouTube channel",
    url: "https://www.youtube.com/channel/UCJpCQcfOPQghHGcfecxEEVA",
  },
]
