import * as React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/Hero"

const IndexPage = () => (
  <Layout>
    <SEO title="Pilot Bikes Coming soon! Be sure to come back and check our our website soon" />
    <Hero />
  </Layout>
)

export default IndexPage
