import React from "react"
import styled from "styled-components"
import { LinksData } from "../data/LinksData"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

const Hero = () => {
  const data = useStaticQuery(
    graphql`
      query {
        desktop: file(relativePath: { eq: "main.jpg" }) {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, placeholder: NONE)
          }
        }
      }
    `
  )

  return (
    <HeroContainer>
      <HeroBg>
        <GatsbyImage
          image={data.desktop.childImageSharp.gatsbyImageData}
          objectFit="cover"
          loading="eager"
          alt="PilotBikesBgPhoto"
          style={{ width: `100%`, height: `100%` }}
        />
      </HeroBg>
      <HeroContent>
        <HeroItems>
          <HeroH1>The Handmade Wooden Bicycle</HeroH1>
          <HeroP>Coming soon!</HeroP>
        </HeroItems>
        <LinksPositionDiv>
          <Wrapper>
            {LinksData.map((item, index) => {
              return (
                <StatsBox key={index}>
                  <StyledLink href={item.url} target="_blank">
                    <Icon>{item.icon}</Icon>
                    <Title>{item.title}</Title>
                  </StyledLink>
                </StatsBox>
              )
            })}
          </Wrapper>
        </LinksPositionDiv>
      </HeroContent>
    </HeroContainer>
  )
}

export default Hero

const StyledLink = styled.a`
  text-decoration: none;
  color: #fff;
  outline: none;
  border: none;

  &:visited {
    text-decoration: none;
    color: #fff;
    outline: none;
    border: none;
  }

  &:hover {
    text-decoration: none;
    color: #fff;
    outline: none;
    border: none;
  }

  &:active {
    text-decoration: none;
    color: #fff;
    outline: none;
    border: none;
  }
`

const HeroContainer = styled.div`
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding: 0 1rem;
  position: relative;
  margin-top: -180px;
  color: #fff;
`

const HeroItems = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100vh;
  max-height: 100%;
  padding: 0;
  color: #fff;
  line-height: 1.1;
  font-weight: bold;
`
const HeroContent = styled.div`
  z-index: 3;
  height: calc(100vh - 80px);
  max-height: 100%;
  padding: 0rem calc((100vh - 1300px) / 2);
`

const HeroH1 = styled.h1`
  font-size: clamp(1.5rem, 6vw, 4rem);
  margin-bottom: 1.5rem;
  letter-spacing: 3px;
  padding: 0 1rem;
`
const HeroP = styled.p`
  font-size: clamp(1rem, 3vw, 3rem);
  margin-bottom: 2rem;
  font-weight: 400;
`
//dugmici
const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 10px;

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  }

  @media screen and (max-width: 500px) {
    grid-template-columns: 1fr;
  }
`
const StatsBox = styled.div`
  height: 100;
  width: 100%;
  padding: 1rem;
  cursor: pointer;

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  }

  @media screen and (max-width: 500px) {
    grid-template-columns: 1fr;
  }
`
const Icon = styled.div`
  font-size: 3rem;
  margin-bottom: 1rem;
`
const Title = styled.p`
  font-size: clamp(1rem, 2.5vw, 1.5rem);
  margin-bottom: 0.5rem;
`

const LinksPositionDiv = styled.div`
  z-index: 150;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 20rem;

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    width: 20%;
  }

  @media screen and (max-width: 500px) {
    grid-template-columns: 1fr;
    width: 25%;
  }

  @media screen and (max-width: 280px) {
    width: 34%;
  }
`
//kraj dugmici sa strane

const HeroBg = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`
